import './App.css';
import {md} from './Description'
import Grid from '@mui/material/Grid'
import React from 'react'
import Typography from '@mui/material/Typography';
import Markdown from 'react-markdown'


function About() {
  
  return (
    <div className="App">
      <header className="App-header">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
        >   
        <Grid
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ mx: 'auto', width: '80vw' }}
            >
            <Markdown
            className="description-md"
            components={Typography}
            >{md}</Markdown>

        </Grid>
            
            
          
      </Grid>  
      </header>
      
    </div>
  );
}

export default About;
