import TextField from '@mui/material/TextField'
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: '#f6bd60',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#f6bd60',
    },
    '& .MuiInputLabel-root': {
      color: '#f6bd60',
    },
    '& .MuiOutlinedInput-input': {
      color: '#f6bd60',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#f6bd60',
      },
      '&:hover fieldset': {
        borderColor: '#f6bd60',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#f6bd60',
      },
    },
});
export default StyledTextField