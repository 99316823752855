const md = `
# **Wi-Fi QR Code Generator**

The **Wi-Fi QR Code Generator** is a powerful tool that simplifies connecting to Wi-Fi networks. Whether you’re at home, in a cafe, or running a business, this free online service lets you create custom QR codes that encode your Wi-Fi credentials. Here’s how it works:

1. **Easy Connection**: Instead of manually selecting a network and typing in a password, users can scan the generated QR code with their smartphone camera. Instantly, they’ll be connected to your Wi-Fi network—no hassle, no fuss.
2. **Never Expires**: The QR codes you create using our generator will never expire, unless you change the settings on your router. So feel free to share them with friends, family, or customers without worrying about time limits.
3. **Hidden Networks**: If your Wi-Fi network is hidden (i.e., not broadcasted), no problem! Just tick the “Hidden” checkbox when generating the QR code.

## **How to Create a Wi-Fi QR Code:**

1. Enter the **network name (SSID)** exactly as it appears on your router.
2. Provide the **Wi-Fi password** (case-sensitive)
3. Generate and download your free custom QR code!

## **What’s Stored in a Wi-Fi QR Code?**

When someone scans your QR code, their QR code reader will extract the following information:

- **Authentication Type (T)**: WPA, WPA2, or None (for open networks).
- **Network SSID (S)**: Your Wi-Fi network’s name.
- **Password (P)**: Ignored if no password is set.
- **Hidden Network (H)**: Optional—true if the SSID is hidden.

## **Joining a Wi-Fi Network with a QR Code:**

Simply open your smartphone camera, point it at the QR code, and a notification will prompt you to connect to the wireless network. If it doesn’t work, ensure that QR code scanning is enabled in your settings or download a third-party QR code scanner app.

## **Benefits of Sharing Wi-Fi via QR Code:**

- **Efficiency**: No more manual input; just scan and connect.
- **Convenience**: Share your Wi-Fi easily with guests.
- **Security**: Keep your password private while allowing seamless access.
`

export {md}