import './App.css';
import Button from '@mui/material/Button'
import StyledTextField from './StyledTextField'
import Grid from '@mui/material/Grid'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoIcon from '@mui/icons-material/Info';
import React, { useRef, useState } from 'react'
import Tooltip from '@mui/material/Tooltip';
import QRCode from 'qrcode'


function App() {

  const nameRef = useRef('')
  const passRef = useRef('')
  const [hiddenNetwork, setHiddenNetwork] = useState(false)

  const handleSetHiddenNetwork = () => {
    setHiddenNetwork(hn => !hn)
  }

  const generateQRCode = () => {
    if (nameRef.current.value === "") {
      alert("Please enter Wifi Name")
      return
    }
    if (passRef.current.value === "") {
      alert("Please enter Wifi Password")
      return
    }
    const canvas = document.createElement("canvas");
    const hiddenString = hiddenNetwork ? "true":"false";
    const data = `WIFI:S:${nameRef.current.value};T:WPA;P:${passRef.current.value};H:${hiddenString};;`
    QRCode.toCanvas(canvas, data, function (error) {
      if (error) console.error(error)
    })
    var image = new Image();
    image.id = "qr-code-png";
    image.src = canvas.toDataURL();
    document.getElementById('qr-code-png-div').appendChild(image);
  }
  const downlaod = () => {
    const img = document.getElementById("qr-code-png")
    if (img == null) {
      alert("Please generate a QR Code first!")
      return
    }
    var link = document.createElement('a');
    link.download = 'mywifiqrcode.png';
    link.href = img.src
    link.click();
  }
  
  return (
    <div className="App">
      <header className="App-header">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
        >   
            <QrCodeScannerIcon
              style={{
                  fontSize: 100,
                  color: '#84a59d'
                }}
              sx={{ m:2 }}
              />
            <h5 color='black'>
              Connect your WI-FI with QR Code
            </h5>
            <StyledTextField
              id="wifi-name"
              label="Wifi Name"
              inputRef={nameRef}
              sx={{ m:1 }}
              />
            <StyledTextField
              id="wifi-password"
              label="Wifi Password"
              inputRef={passRef}
              sx={{
                m:1,
                
                "& .MuiOutlinedInput-root": { "&.Mui-focused fieldset": { borderColor: "#f6bd60", }, }, 
              }}
              />
            <FormGroup>
              <Grid container alignItems={'center'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleSetHiddenNetwork}
                      sx={{
                        color: '#f28482',
                        '&.Mui-checked': {
                          color: '#f28482',
                        },
                      }}
                    />
                  }
                  label="Hidden Network" 
                />
                <Tooltip title="Check this box if your Wi-Fi network is not broadcasted (hidden)" enterTouchDelay={0}>
                  <InfoIcon
                    sx={{
                      color: '#f28482'
                    }}
                  />
                </Tooltip>
              </Grid>
            </FormGroup>

            <Button
              variant="contained"
              disableElevation
              onClick={generateQRCode}
              endIcon={<QrCodeIcon />}
              sx={{ m:1 }}
              style={{
                backgroundColor: '#f28482'
              }}
              >
              Generate QR Code
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={downlaod}
              endIcon={<ArrowCircleDownIcon />}
              sx={{ m:1 }}
              style={{
                backgroundColor: '#f28482'
              }}
              >
              Download Image
            </Button>
            <div id="qr-code-png-div"></div>
      </Grid>  
      </header>
      
    </div>
  );
}

export default App;
