import { AppBar, Toolbar, Typography } from "@mui/material"
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { Link } from "react-router-dom";
import {Grid} from "@mui/material";
  
function TopAppBar() {
    return (
        <AppBar
            position="static"
            elevation={0}
            style={{ background: 'transparent', boxShadow: 'none'}}
            >
            <Toolbar>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid item>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <Grid
                                alignItems={'center'}
                                container
                            >
                                <QrCodeScannerIcon sx={{color: "black"}}/>
                                <Typography noWrap sx={{color: "black"}}>
                                    <strong>
                                        WI-FI QR Code Converter
                                    </strong>
                                </Typography>
                            </Grid>
                        </Link>

                    </Grid>

                    <Grid item>
                        <Link to="/about" style={{ textDecoration: 'none' }}>
                            <Typography noWrap sx={{color: "grey"}}>
                                About
                            </Typography>
                        </Link>
                    </Grid>


                </Grid>

            </Toolbar>
        </AppBar>
    )
}

export default TopAppBar;